module.exports = [{
      plugin: require('/codebuild/output/src176962190/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.motorsportfans.se"},
    },{
      plugin: require('/codebuild/output/src176962190/src/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145435918-1"},
    },{
      plugin: require('/codebuild/output/src176962190/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
